.table-mega-container::-webkit-scrollbar {
    width: 0 !important;
}


.table-mega-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 15px;
}

/* 
.Fleet-table tr {
    border-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
}
 */

.edit-btn {
    background-color: #c6edff;
    color: #10648b;
    padding: 6px 9px;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
}

.dlt-btn {
    padding: 6px 9px;
    background-color: #ff9090;
    color: #af0d0d;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
}

.branch-btn {
    padding: 6px 9px;
    background-color: #cbcbcb;
    color: rgb(38, 38, 38);
    border-radius: 5px;
    cursor: pointer;
}

.fd:hover {
    text-decoration: underline;
    cursor: pointer;
    color: rgb(67, 67, 231);

}

.Active {
    background-color: green;
    color: #fff;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 10px;
}

.Inactive {
    background-color: red;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    font-size: 10px;
}

/* .action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eye-btn {
    background-color: #F3F2AD;
    padding: 6px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.spp-edit-btn {
    background-color: #0400CF;
    padding: 7px;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
}

.spp-dlt-btn {
    padding: 7px;
    background-color: #cf0000;
    border-radius: 5px;
    cursor: pointer;
} */