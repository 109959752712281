.lead-details {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: white;
    display: flex;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    padding: 15px 0px;
}

.main-container-lead {
    width: 30%;
    padding: 25px 30px;
}

.main-container-personal h3 {
    padding: 3px 0px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.main-container-lead .detail {
    display: grid;
    padding: 5px 0px;
    width: 100%;
    font-weight: 500;
    font-size: 1vw;
    margin: 5px;
}

.main-container-lead .detail span {
    color: #6e7284;
    font-size: 0.9vw;
    font-weight: 500;
}

.line {
    border: 0.3px solid rgba(0, 0, 0, 0.15);
    margin: 5px 0px;
}


.status-show-chip {
    position: absolute;
    left: 55px;
    top: 20;
    background-color: #EBEBE4;
    border-radius: 12px;
    /* text-transform: uppercase; */
    font-weight: normal !important;
    padding: 4px 32px;
    color: #000 !important;
    font-size: 0.8vw !important;
}

.status-show-chip-order-generated {
    position: absolute;
    left: 55px;
    top: 20;
    background-color: #60ebe1;
    border-radius: 12px;
    /* text-transform: uppercase; */
    font-weight: normal !important;
    padding: 4px 32px;
    color: #000 !important;
    font-size: 0.8vw !important;
}

.status-show-chip-hot {
    position: absolute;
    color: #fff;
    left: 55px;
    top: 20;
    background-color: #E21F27;
    border-radius: 12px;
    /* text-transform: uppercase; */
    font-weight: normal !important;
    padding: 4px 32px;
    color: #fff !important;
    font-size: 0.8vw !important;
}

.status-show-chip-warm {
    position: absolute;
    color: #fff;
    left: 55px;
    top: 20;
    background-color: #F89B1C;
    border-radius: 12px;
    /* text-transform: uppercase; */
    font-weight: normal !important;
    padding: 4px 32px;
    color: #fff !important;
    font-size: 0.8vw !important;
}

.status-show-chip-cold {
    position: absolute;
    color: #fff;
    left: 55px;
    top: 20;
    background-color: #01A0BE;
    border-radius: 12px;
    /* text-transform: uppercase; */
    font-weight: normal !important;
    padding: 4px 32px;
    color: #fff !important;
    font-size: 0.8vw !important;
}

.cheaps-styleout-modal {
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: normal;
    color: #36C991;
    border-radius: 10px;
    padding: 4px 10px;
    background: #EAFAF4;
    text-align: center;

}

.product-category-view {
    width: 100%;
    font-size: 0.9vw;
    display: grid;
    gap: 6px;
    background-color: #f1fff6;
    border-radius: 8px;
    padding: 5px;
}