.notification-box {
  width: 100%;
  height: 100%;
}
.notification-box > h2 {
  padding: 10px; 
}
.messages-popup-content {
  width: 100%;
  height: 90%;
  padding: 10px;
  overflow: scroll;
}
.messages-popup-content .notification-list {
  background-color: #fff;
  width: 100%;
  height: 80px;
  margin: 8px 0px;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.65);
  color: #000;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;

}

.notification-empty {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
}


@media only screen and (max-width: 940px) {
  .messages-popup-content .notification-list  {
    font-size: 14;
  }
}

@media only screen and (max-width: 836px) {
  .messages-popup-content .notification-list  {
    font-size: 12;
  }
}


.mark-icon-notification {
  position: absolute;
  left: 50%;
  top: -4%;
  transform: translateX(-50%);
  transition: all 200ms ease-in-out;
  border: none;
  outline: none;
  background: #000;
  border-radius: 50%;
  padding: 6px;
}


.mark-icon-notification .icon-mark {
  width: 40px;
  height: 40px;
  color: white;
  cursor: pointer;
}

.button-mark-read {
  padding: 0.5rem 1.5rem;
  border: none;
  background-color: #eae8e8;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1vw;
  margin: 0px auto 10px auto;
  width: 180px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: all 200ms ease-in-out;
  margin-bottom: 22px;
}

.button-mark-read:hover {
  background-color: #dddbdb;
}

.table-content-notification thead th {
  text-align: center;
  text-transform: capitalize;
}

.table-content-notification tbody tr {
  text-align: center;
  text-transform: capitalize;
}
