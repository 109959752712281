.right-container {
    width: 70%;
}

.recent-inspection {
    padding: 20px;
    height: 350px;
    width: 100%;
    background-color: white;
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
}

.right-container h2 {
    /* margin: 20px; */
    color: #6C6C6B;
    font-size: 20px;
    font-weight: 600;
}

.recent-inspection .table-container{
    height: 90%;
    overflow: scroll;
}

.recent-inspection-table{
    /* height: 80%; */
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 15px;
    background-color: white;
    overflow: scroll;
}

.recent-inspection-table th{
    font-weight: 700;
    background-color: white;
    height: 30px;
    font-size: 0.9vw;
    color: black;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
}

.recent-inspection-table td{
    text-transform: capitalize;
    margin-top: 5px;
    padding: 6px;
    text-align: center;
    font-size: 0.9vw;
    font-weight: 600;
    background-color: #daf0ff;
    height: 100%;
    border-bottom: 5px solid white; /* Adjust the border thickness as needed */

}

.recent-inspection .tyre-quantity{
    background-color:#53b7fa;
    width:30px;
    height:30px;
    border-radius:15px;
    padding:4;
    font-size:0.8vw;
    text-align: center;
    display: grid;
    align-items: center;
    margin: 0px auto;
}


/* CSS */
/* .assign-button{
    align-items: center;
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1vw;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 5px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.assign-button:hover,
.assign-button:focus {
  background-color: #F082AC;
} */

@media only screen and (max-width: 1300px){
    .recent-inspection {
        /* height: 270px; */
    }
}