.right-container {
    width: 70%;
    gap: 20px;
    display: grid;
}

.pending-inspection {
    padding: 20px;
    height: 350px;
    width: 100%;
    background-color: white;
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    /* overflow: scroll; */
}
/* 
.recent-inspection {
    padding: 20px;
    height: 360px;
    width: 100%;
    background-color: white;
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
} */

.right-container h2 {
    /* margin: 20px; */
    color: #6C6C6B;
    font-size: 20px;
    font-weight: 600;
}

.pending-inspection .table-container {
    height: 90%;
    overflow: scroll;
}

.pending-inspection-table {
    /* height: 80%; */
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 15px;
    background-color: white;
    overflow: scroll;
}

.pending-inspection-table th {
    font-weight: 700;
    background-color: white;
    height: 30px;
    font-size: 0.9vw;
    color: black;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
}

.pending-inspection-table td {
    text-transform: capitalize;
    margin-top: 5px;
    padding: 6px;
    text-align: center;
    font-size: 0.85vw;
    font-weight: 600;
    background-color: rgba(233, 136, 98, 0.10);
    height: 100%;
    border-bottom: 5px solid white;
    /* Adjust the border thickness as needed */
}

.tyre-quantity {
    /* background-color: #E98862; */
    background-color: rgba(121, 28, 147, 0.72);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 4;
    font-size: 0.8vw;
    text-align: center;
    display: grid;
    align-items: center;
    margin: 0px auto;
}



.assign-button:hover,
.assign-button:focus {
    background-color: #F082AC;
}

.empty-data {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

/* .generateRequestBtn {
    border: 1px solid green;
    height: 2rem;
    width: 150px;
    border: none;
    color: white;
    background-color: #53a336;
    border-radius: 6px;
    box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
    font-size: 0.9vw;
    font-weight: 400;
    cursor: pointer;
} */

.close-modal{
    height: 50px;
    width: 50;
    /* color: pink !important; */
    background: transparent !important;
}
.close-modal svg{
    color: darkgray !important;
}
@media only screen and (max-width: 1300px) {
    .pending-inspection {
        /* height: 270px; */
    }
}