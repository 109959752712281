.tyre-count-modal{
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tyre-count-modal .submit-button{
    margin: auto auto 0px auto;
    background-color: #65a143;
    padding: 6px 15px;
    font-size: 0.95vw;
    border-radius: 8px;
    color: white;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
}

.tyre-count-modal .info{
    font-size: 0.9vw;
    font-weight: 500;
}