.login-mega-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  position: relative;
}
/* 
.line {
  height: 55%;
  width: 1px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: black;
} */

.image-side {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.image-side img {
  height: 100%;
}

.login-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.login-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 49%;
  /* background-color: red; */
  height: 100%;
}

.logo-container {
  flex: 3;
}

.login-card .logo-container img {
  height: 8rem;
}

.login-card .login-heading {
  flex: 1;
  margin: 0.3rem 0.2rem 1rem 0.2rem;
  color: #39532f;
  font-weight: 700;
  font-size: 1.7rem;
}

@media screen and (max-width: 1250px) {
  .image-side img {
    /* width: 25rem; */
  }

  .login-card {
    width: 70%;
  }

  .login-card .login-heading {
    margin: 0.5rem 0.2rem 1rem 0.2rem;
  }

  .login-side {
    height: 75%;
  }
}

@media screen and (max-width: 1000px) {
  .image-side img {
    display: none;
  }

  .line {
    display: none;
  }

  .image-side {
    flex: 0;
  }

  .login-card {
    width: 70%;
  }

  .login-card .login-heading {
    margin: 0.5rem 0.2rem 0.8rem 0.2rem;
  }

  .login-side {
    height: 75%;
  }
}

@media screen and (max-width: 800px) {
  .login-card .login-heading {
    font-size: 1.5rem;
    margin: 0.5rem 0.2rem 0.8rem 0.2rem;
  }

  .login-card {
    width: 70%;
  }

  .login-side {
    height: 75%;
  }
}

.login-card .login-form {
  flex: 6;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.login-input {
  width: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.login-input input {
  width: 100%;
  height: 1.7rem;
  border: none;
  border-bottom: 2px solid #65a143;
  position: relative;
}

.login-input input::placeholder {
  font-size: 14px;
}

.login-input input:focus::placeholder {
  letter-spacing: 1px;
  transition: 0.3s ease;
}

.login-input input:focus {
  outline: none;
  /* height: 2rem; */
  transition: 0.3s ease;
  border-bottom: 2px solid #39532f;
}

.login-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-remember-me {
  display: flex;
  flex: 1;
  gap: 5px;
  align-items: center;
  font-size: 12px;
}

.login-remember-me input {
  transform: scale(1.2);
}

.login-bottom a {
  color: black;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.login-button {
  width: 9rem;
  border: none;
  color: white;
  background-color: #65a143;
  padding: 0.6rem 0rem;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.login-terms {
  color: #00000074;
  font-size: 12px;
}

.login-terms span {
  text-decoration: underline;
}
.label-input {
  /* border: 1px solid grey; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-input label {
  margin-right: 10px; /* Adjust the value as needed */
}

@media screen and (max-width: 530px) {
  .login-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .line {
    display: none;
  }

  .image-side {
    display: none;
  }

  .login-side {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75%;
  }

  .login-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 72%;
    /* background-color: red; */
    height: 100%;
  }

  .login-terms {
    width: 100%;
    color: #00000074;
    font-size: 13px;
  }
}
