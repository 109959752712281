.modal-main-div {
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.addsupplier-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #F1F1F1;
    width: 28rem;
    height: 60%;
    border-radius: 0.8rem;
}

.addSupplier-head {
    display: flex;
    justify-content: space-between;
    background-color: #39532F;
    padding: 0.7rem 0.7rem;
    align-items: center;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.addSupplier-head>.heading {
    display: flex;
    gap: 20px;
    align-items: center;
}

.heading h2 {
    font-size: 20px;
    color: #F3F2AD;
    font-weight: 400;
}

.heading>.edit-btn {
    font-size: 16px;
    color: #F3F2AD;
    cursor: pointer;
}

.addSupplier-head>.close-btn>button {
    background: none;
    border: none;
    font-size: 19px;
    margin: 6px 5px 0 0;
    cursor: pointer;
}

.addsupplier-form {
    background-color: #eae9e9;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 1.4rem;
    height: 100%;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    overflow-y: auto;
    gap: 12px;
}

.addsupplier-form::-webkit-scrollbar {
    width: 19px;
    background-color: #eae9e9;
    height: 1rem;
    border-bottom-right-radius: 2rem;
}

.addsupplier-form::-webkit-scrollbar-track {
    background-color: #eae9e9;
    border-bottom-right-radius: 0.6rem;
}

.addsupplier-form::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
    background-color: #eae9e9;
}

.addsupplier-form::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
    background-color: #eae9e9;
}

.addsupplier-form::-webkit-scrollbar-thumb {
    background-color: #588048;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
}

.addsupplier-form-input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 2px;
}

.addsupplier-form-input label {
    font-size: 14px;
    margin-bottom: 4px;
}

.addsupplier-form-input input {
    height: 35px;
    padding: 5px 10px;
    border: 1px solid hsl(210, 8%, 75%);
    border-radius: 0.5rem;
    color: hsl(0, 0%, 20%);
}

.addsupplier-form-input .selector{
    /* border-radius: 1rem; */
    height: 35px;
    min-height: 0px;
    font-size: 14px;
}
.css-13cymwt-control{
    border-radius: 0.5rem !important;
    min-height: none;
    height: 38px;
}
.css-qbdosj-Input{
    height: 30px;
}
.chk-inp,
.chk-inp-2 {
    height: 10px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 0.4rem;
}

.chk-inp label,
.chk-inp-2 label {
    font-size: 12px;
    color: #53a336;
    margin: 0;
}

input[type='checkbox'] {
    accent-color: #39532F;
}

#gst-checkbox,
#bank-details-checkbox {
    padding: 0;
    margin: 2px 5px 0 1px;
}

.addSupplier-submit-btn {
    margin-top: 0.6rem;
    padding: 0.5rem 2rem;
    border: none;
    background-color: #65A143;
    color: #F3F2AD;
    border-radius: 5px;
    cursor: pointer;
}

/* .gst-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
} */