.assignment-request {
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

.assignment {
    display: flex;
    flex-direction: column;
}

.assignment-request .download-uploaded-excel{
    font-weight: 600;
    font-size: 0.9vw;
    padding: 7px 12px;
    background-color: white;
    border-radius: 8px;
    border: 0.5px solid rgb(0,0,0,0.6);
    width: auto;
    cursor: pointer;
    gap: 7px;
    display: flex;
    align-items: center;
}

.assignment-request .download-uploaded-excel:hover{
    background-color: grey;
    color: white;
    border: 0.5px solid grey;
}
.assignment-request .card-container {
    flex-wrap: wrap;
    /* flex: 1; */
    gap: 20px;
    display: flex;
    width: 100%;
    margin-top: 40px;
}


.assignment-request .card {
    width: 100%;
    /* height: 300px; */
    border-radius: 20px;
    padding:20px 40px;
    justify-content: space-between;
    /* box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25); */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
}

.assignment-request .card .card-header{
    display: flex;
    justify-content: space-between;
    width: 99%;
    margin: 0 auto 15px auto;
    align-items: center;
}

.assignment-request .card .card-header .field{
    display: flex;
    gap: 5px;
    align-items: center;
}

.assignment-request .card .card-header .field .field-request{
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 0.85vw;
}

.assignment-request .card .card-header .field .data{
    background-color: rgb(0,0,0,0.8);
    width: 28px;
    height: 28px;
    border-radius: 15px;
    font-size: 0.8vw;
    text-align: center;
    display: grid;
    align-items: center;
    margin: 0px auto;
    color: white;
    padding-bottom: 2px;
}

.assignment-request .card .card-header .field .field-button{
    color: black;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #e0d68a;
    gap: 5px;
    display: flex;
    border: none;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.request-table {
    /* height: 80%; */
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 15px;
    background-color: white;
    overflow: scroll;
}

.request-table th {
    font-weight: 700;
    background-color: white;
    height: 30px;
    font-size: 0.9vw;
    color: black;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
    border-bottom: 0.6px solid rgb(0,0,0,0.6);
}

.request-table td {
    text-transform: capitalize;
    margin-top: 5px;
    padding: 6px;
    text-align: center;
    font-size: 0.9vw;
    font-weight: 600;
    background-color: white;
    height: 100%;
    border-bottom: 0.5px solid rgb(0,0,0,0.2);
    /* Adjust the border thickness as needed */
}

.assignment-request .status-container{
    display: flex;
    justify-content: space-between;
    padding: 8px 25px;
    border-radius: 10px;
    width: 400px;
    align-items: center;
}

.assignment-request .status-container .status{
    display: flex;
    align-items: center;
    text-align: left;
    gap: 2px;
    width: 30%;
}

/* .assignment-request .status-container .status1{

} */

.assignment-request .status-container .status .data{
    width: 25px;
    height: 25px;
    border-radius: 15px;
    font-size: 0.8vw;
    text-align: center;
    display: grid;
    align-items: center;
    margin: 0px auto;
    color: white;
}


.assignment-request .action-button{
    background-color: #ceeaef;
    color: white;
    padding: 5px 25px;
    border-radius: 30px;
    border: none;
    width: 150px;
    /* display: flex; */
    text-align: center;
    align-items: center;
    margin: 0px auto;
    font-weight: 500;
    font-size: 0.9vw;
    cursor: pointer;
}

.assignment-request .assignment-date{
    font-size: 0.9vw;
    font-weight: 500;
}