.Bank-details-view-modal {
    position: fixed;
    z-index: 40;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-header h2 {
    color: #65A143;
    margin: 0;
    font-weight: 400;
    font-size: 2rem;
}

.Bank-details-form {
    border-left: 20px solid #65A143;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #F1F1F1;
    border-radius: 0.6rem;
    display: flex;
    padding: 20px 25px;
}

.Bank-details-form label {
    font-size: 0.8rem;
}

.Bank-details-form input {
    margin-top: 5px 0;
    font-size: 1.1rem;
    padding: 5px 25px;
    padding: 5px 70px 5px 10px;
    border-radius: 10px;
    border: .5px solid rgb(212, 211, 211)
}

.Bank-details-form input:focus {
    outline: none;
}


.Bank-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.bankDetails-form-input {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    justify-content: space-between;

}

.close-btn button {
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
}