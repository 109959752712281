.generate-request-excel .download-uploaded-excel{
    font-weight: 600;
    font-size: 0.9vw;
    padding: 7px 12px;
    background-color: white;
    border-radius: 8px;
    border: 0.5px solid rgb(0,0,0,0.6);
    margin-left: auto;
    width: auto;
    cursor: pointer;
    gap: 7px;
    display: flex;
    align-items: center;
}

.download-uploaded-excel:hover{
    background-color: grey;
    color: white;
    border: 0.5px solid grey;
}