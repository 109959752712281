.edit-modal-main-div {
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(117, 116, 116, 0.144);
    backdrop-filter: blur(1px);
}


.editTyre-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #F7F7F7;
    width: 35rem;
    height: 70%;
    border-radius: 0.8rem;
}

.editTyre-head {
    display: flex;
    justify-content: space-between;
    background-color: #39532F;
    padding: 0.7rem 0.7rem;
    align-items: center;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.editTyre-head>.heading {
    display: flex;
    gap: 20px;
    align-items: center;
}

.heading h2 {
    font-size: 20px;
    color: #F3F2AD;
    font-weight: 400;
}

.heading>.edit-btn {
    font-size: 16px;
    color: #F3F2AD;
    cursor: pointer;
}

.editTyre-head>.close-btn>button {
    background: none;
    border: none;
    font-size: 19px;
    margin: 6px 5px 0 0;
    cursor: pointer;
}

.update-tyre-form {
    background: #F7F7F7;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 1.4rem;
    height: 100%;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    overflow-y: auto;
    gap: 12px;
}

.update-tyre-form::-webkit-scrollbar {
    width: 19px;
    background-color: #F7F7F7;
    height: 1rem;
    border-bottom-right-radius: 2rem;
}

.update-tyre-form::-webkit-scrollbar-track {
    background-color: #F7F7F7;
    border-bottom-right-radius: 0.6rem;
}

.update-tyre-form::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
    background-color: #F7F7F7;
}

.update-tyre-form::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
    background-color: #F7F7F7;
}

.update-tyre-form::-webkit-scrollbar-thumb {
    background-color: #588048;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
}

.editTyre-form-input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.editTyre-form-input label {
    font-size: 14px;
    margin-bottom: 2px;
}

.editTyre-form-input input {
    width: 31.2rem;
    padding: 10px;
    margin-top: 7px;
    border-radius: 5px;
    border: 1px solid hsl(210, 8%, 75%);

}

.editTyre-form-input select {
    width: 31.2rem;
    padding: 10px;
    margin-top: 7px;
    border-radius: 5px;
    border: 1px solid #babfc4;
}

.chk-inp,
.chk-inp-2 {
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 0.4rem;
}

.chk-inp label,
.chk-inp-2 label {
    font-size: 12px;
    color: #53a336;
    margin: 0;
}

input[type='checkbox'] {
    accent-color: #39532F;
}

#gst-checkbox,
#bank-details-checkbox {
    padding: 0;
    margin: 2px 5px 0 1px;
}

.editTyre-submit-btn {
    margin-top: 0.6rem;
    padding: 0.5rem 2rem;
    border: none;
    background-color: #65A143;
    color: #F3F2AD;
    border-radius: 5px;
    cursor: pointer;
}

.Update-images-div {
    margin-top: 5px;
    display: flex;
    background-color: #FFF;
    border-radius: 5px;
    height: 7.7rem;
    width: 31.2rem;
    /* border: 1px solid #babfc4; */
}

.Update-images-div .img-div {
    margin-right: 0.3rem;
    border-radius: 5px;
    padding: 0.7rem 0.4rem 0.7rem 0.7rem;
    position: relative;
    cursor: pointer;
}

.Update-images-div .img-div .img-length {
    position: absolute;
    background-color: rgb(195, 86, 19);
    color: #ffffff;
    padding: 2px 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 85px;
    left: 5.5em;
}

.Update-images-div .img-div .img-length span {
    font-size: 13px;
}

.Update-images-div .img-desc-div {
    padding: 0 0.3rem;
    width: 25rem;
    padding: 0.7rem 0.3rem 0.7rem 0.2rem;
}

.Update-images-div .img-desc-div p {
    border: 1px solid black;
    border-radius: 5px;
    height: 4.2rem;
    padding: 4px 5px;
}

.img-desc-div h2 {
    font-size: 15px;
    font-weight: 500;
}

.img-desc-div p {
    margin-top: 10px;
    font-weight: 400;
}

.defect-name {
    margin-bottom: 7px;
}

.Update-images-div .img-edit-icon {
    background-color: #F3F2AD;
    /* margin: 3px; */
    width: 10.1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    /* box-shadow: -2px 1px 6px #36363688; */
}