.tyre-inspection-modal {
    position: absolute;
    background: rgba(255, 255, 255, 0.521);
    height: 95%;
    width: 95%;
    z-index: 20;
    border: 3px solid black;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

.inspection-close-button {
    position: absolute;
    right: 5px;
    top: 1px;
    padding: 2px 4px;
    background-color: transparent;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: 1px solid black;
    font-weight: 800;
    color: black;
}

.inspection-form {
    /* background-color: rgba(128, 0, 128, 0.197); */
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.inspection-input {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.inspection-submit-button {
    font-size: 15px;
    border: 2px solid #2B2A29;
    color: #2B2A29;
    font-weight: 600;
    width: 50%;
    padding: 0.15rem;
    background-color: #f4f2ac;
}

.inspection-submit-button:hover {
    cursor: pointer;
    transition: 0.2s ease;
    color: white;
    background-color: #65A143;
}

.view-img-btn img {
    cursor: pointer;
    border-radius: 5px;
}