.heading1 {
  color: #65a143;
  font-size: 2vw;
  font-weight: 500;
}

.table-heading th {
  font-weight: 400;
  background-color: #39532f;
  height: 60px;
  font-size: 0.9vw;
  color: #f3f2ad;
  padding: 1rem;
  text-align: center;
  text-transform: capitalize;
}

.table-data td {
  padding: 0.8rem;
  text-align: center;
  font-size: 0.9vw;
  font-weight: 600;
  background-color: #F7F7F7;
  height: 100%;
  align-items: center;
}

.header-button {
  padding: 0.4rem 1rem;
  height: 2rem;
  margin-top: 1rem;
  border: none;
  color: #ffffff;
  background-color: #000000;
  border-radius: 6px;
  box-shadow: 4px 4px 4px rgba(53, 53, 53, 0.523);
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9vw;
  margin-right: 5px;
}

.cancel-button {
  padding: 0.4rem 1rem;
  height: 2rem;
  margin-top: 1rem;
  border: none;
  background-color: white;
  border-radius: 6px;
  /* box-shadow: 4px 4px 4px rgba(53, 53, 53, 0.523); */
  border: 0.5px solid black;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9vw;
  margin-right: 5px;
}

.supplier-name-column {
  text-transform: capitalize;
}

.detailed-modal-head {
  /* right: 20; */
  left: 0;
  top: 0;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  background-color: #F1F1F1;
  height: 90px;
  padding: 0px 30px;
}

.skeleton td {
  height: 70px;
}

.modal-main-div {
  position: fixed;
  z-index: 20;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #F1F1F1;
  width: 23rem;
  height: 37%;
  border-radius: 0.8rem;
}

.modal-head {
  display: flex;
  justify-content: space-between;
  background-color: #39532F;
  padding: 0.7rem 0.7rem;
  align-items: center;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}

/* Scrollbar CSS */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}


.modal-main-div {
  position: fixed;
  z-index: 20;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #F1F1F1;
  width: 23rem;
  height: 37%;
  border-radius: 0.8rem;
}

.modal-head {
  display: flex;
  justify-content: space-between;
  background-color: #39532F;
  padding: 0.7rem 0.7rem;
  align-items: center;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}

.assign-button {
  align-items: center;
  background-color: #EA4C89;
  border-radius: 80px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.85vw;
  font-weight: 500;
  height: 25px;
  line-height: 15px;
  margin: 0;
  padding: 4px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  z-index: 0;
}


.confirmation-modal {
  padding: 10px 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
}


.confirmation-content {
  margin: 15px auto;
}

.confirmation-button-container {
  display: flex;
  gap: 15px;
  width: fit-content;
  margin: auto;
}

.heading-container{ 
  margin: 20px;
}
.heading-container h2{
  color: grey;
}


@media only screen and (max-width: 1280px) {
  .table-heading th {
    height: 50px;
    padding: 0.5rem 1rem;
  }

  .table-data td {
    padding: 0.4rem;
    height: 50px;
  }

  .skeleton td {
    height: 50px;
  }
}


.table-mui-head-skeleton {
  z-index: -2;

}