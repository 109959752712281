.capture-images-modal-container{
    position: absolute;
    height:100%;
    width:100%;
    z-index:30;
    background-color: white;
    display: flex;
    gap:20px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.capture-images-button-container{
    flex:2;
    display: flex;
    width:100%;
    height:8rem;
    align-items:center;
    gap:40px;
    justify-content:center;
    padding:0rem 2rem;
}

.capture-images-box{
    height:4rem;
    width:4rem;
    position: relative;
    border:3px solid black;
    display: flex;
    flex-direction:column;
    gap:20px;
    padding:0rem 0.5rem; 
    justify-content: center;
    align-items: center;
}

.capture-images-heading{
    font-size: 20px;
}

.capture-images-box input[type="file"] { 
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0; /* make transparent */
  }

.capture-images-camera-button{
    background-color: transparent;
    border:none;
    height:100%;
    width:100%;
    text-align: center;
}

.capture-images-container{
    flex:4;
    margin:1rem 0rem;
    width:95%;
    border:5px solid black;
    display: flex;
    gap:20px;
    align-items: center;
    padding:0 10px;
    overflow-x: scroll;
}

.capture-images-image-container{
    height:95%;
    position: relative;
    width:max-content;  
    display: flex;
    justify-content: center;
    gap:20px;
    align-items: center;
    flex-direction: column;
    padding:0 10px;
    border:3px solid brown;
}
.capture-image-defect-input{
    display: flex;
    width: max-content;
    gap:20px;   
    justify-content: space-between;
    align-items: center;
}
.capture-image-defect-input label{
    flex: 2;
}
.capture-image-defect-input input{
    flex:1;
}
.capture-images-image-container img{
    height:220px;
}


.close-button{
    position: absolute;
    right:5px;
    top:5px;
}

.capture-images-proceed-button-container{
    width:100%;
    flex:1;
    display:flex;
    justify-content:center;
    gap:30px;
    align-items:center;
}
.capture-images-proceed-button-container button{
    width:6rem;
    height:2.5rem;
    font-size: 1rem;
    background-color: transparent;
    border:2px solid black;
}