.capture-image-modal-container{
    position: absolute;
    height:100%;
    width:100%;
    z-index:30;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.capture-image-modal-heading{
    margin-top:2rem;
    flex:1;
}
.capture-image-button-container{
    flex:1;
    display: flex;
    width:100%;
    height:8rem;
    align-items:center;
    gap:20px;
    justify-content:space-between;
    padding:0rem 2rem;
}

.capture-image-box{
    height:4rem;
    width:4rem;
    position: relative;
    border:3px solid black;
    display: flex;
    flex-direction:column;
    gap:20px;
    padding:0rem 0.5rem; 
    justify-content: center;
    align-items: center;
}

.capture-image-heading{
    font-size: 20px;
}

.capture-image-box input[type="file"] { 
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0; /* make transparent */
  }

.capture-image-camera-button{
    background-color: transparent;
    border:none;
    height:100%;
    width:100%;
    text-align: center;
}


.capture-image-show-container{
    flex:3;
}
.capture-image-show-container img{
    height:15rem;
}

.capture-image-proceed-button-container{
    width:100%;
    flex:1;
    display:flex;
    justify-content:center;
    align-items:center;
}
.capture-image-proceed-button-container button{
    width:6rem;
    height:2.5rem;
    font-size: 1rem;
    background-color: transparent;
    border:2px solid black;
}