.table-mega-container::-webkit-scrollbar {
  width: 0 !important;
}

.supplier-container {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
}

.supplier-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.header-button span a {
  font-size: 0.9vw;
  text-decoration: none;
  color: #fff;
}

.supplier-btn {
  display: flex;
  gap: 10px;
}

.table-mega-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 15px;
}

.supplier-table {
  height: 80%;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 15px;
  background-color: white;
}

.supplier-table tr {
  border-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eye-btn {
  background-color: #f3f2ad;
  padding: 6px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.spp-edit-btn {
  background-color: #0400cf;
  padding: 7px;
  border-radius: 5px;
  margin-right: 1rem;
  cursor: pointer;
}

.spp-dlt-btn {
  padding: 7px;
  background-color: #cf0000;
  border-radius: 5px;
  cursor: pointer;
}
