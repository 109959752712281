.show-serial-images-modal-mega-container {
  height: 100%;
  width: 100%;
  background-color: rgba(117, 116, 116, 0.144);
  /* backdrop-filter: blur(1px); */
  z-index: 200;
  /* position: fixed; */
  /* top: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 0; */
}

.show-serial-images-heading {
  padding-left: 1rem;
  font-size: 20px;
  font-weight: 500;
  color: #65a143;
}

.show-serial-images-modal-container {
  width: 25rem;
  height: 23rem;
  background-color: #f1f1f1;
  padding: 3rem 1rem;
  padding-top: 1.5rem;
  position: relative;
  /* border-radius: 10px; */
}

.show-serial-images-close-btn {
  position: absolute;
  top: 1.8rem;
  right: 2rem;
  cursor: pointer;
}

.serial-carousel-container {
  width: 100%;
  /* height: 30rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
}

.image-serial-container {
  margin-bottom: 10px;
  height: 16rem;
  width: 100%;
  border-radius: 15px;
  border: 1px solid black;
}

.serial-carousel-container img {
  border-radius: 15px;
  height: 100%;
  width: 100%;
}
