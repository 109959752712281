.excel-modal {
    position: fixed;
    z-index: 40;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(41, 41, 41, 0.286);
    backdrop-filter: blur(1px);
}

.excel-Data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #F1F1F1;
    border-radius: 15px;
    width: 90%;
    height: 95%;
    display: flex;
    padding: 90px 25px 0px 25px;
}

.excel-sheet-main-head{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0.6rem;
    gap: 20px;
}

.excel-detail-container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0.6rem;
    gap: 20px;
    overflow-x: auto;
    overflow-y: auto;
}

.excel-detail-container::-webkit-scrollbar {
    width: 19px;
    background-color: #eae9e9;
    height: 1rem;
    border-bottom-right-radius: 2rem;
}

.excel-detail-container::-webkit-scrollbar-track {
    background-color: #eae9e9;
    border-bottom-right-radius: 0.6rem;
}

.excel-detail-container::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
    background-color: #eae9e9;
}

.excel-detail-container::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
    background-color: #eae9e9;
}

.excel-detail-container::-webkit-scrollbar-thumb {
    background-color: #588048;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
}


.close-btn-excel>.download-button {
    background-color: #39532f;
    padding: 2px 10px;
    border: white;
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 1vw;
    cursor: pointer;
    margin-right: 10px;
}

.excel-table-heading th {
    font-weight: 400;
    background-color: #39532f;
    height: 60px;
    font-size: 0.9vw;
    color: #f3f2ad;
    padding: 1rem;
    text-align: center;
    min-width: 130px;
}


.head-left>.date-style {
    font-size: 1.1vw;
    color: #39532f;
}

.head-left {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-around;
}

.excel-table {
    overflow-x: scroll;
    overflow-y: scroll;
    height: 21%;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 6rem;
}


.excel-table tr {
    border-style: none;
    border-bottom: 1px solid black;
}


.close-btn-excel{
    display: flex;
    gap: 2rem;
}

.excel-head{
    display: flex;
    justify-content: flex-end;
    width:100%;
}

.cross-excel{
    border: none;
    font-size: 2rem;
}



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');


.sec-center {
  position: relative;
  max-width: 100%;
  text-align: center;
  z-index: 200;
}
.sec-center >[type="checkbox"]:checked,
.sec-center >[type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.dropdown:checked + label,
.dropdown:not(:checked) + label{
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 2;
  height: 40px;
  transition: all 200ms linear;
  border-radius: 7px;
  width: 190px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  gap: 15px;
  border: none;
  background-color: #39532f;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 12px 35px 0 rgba(255,235,167,.15);
}
.dark-light:checked ~ .sec-center .for-dropdown{
  background-color: #fff;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16,39,112,.25);
  font-size: 12px;
}
.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before{
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}
.dropdown:checked + label:before{
  pointer-events: auto;
}
.dropdown:not(:checked) + label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.dropdown:checked + label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.section-dropdown {
  position: absolute;
  padding: 5px;
  background-color: #fff;
  top: 52px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown {
  background-color: #fff;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.15);
}
.dropdown:checked ~ .section-dropdown{
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}
.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid #fff;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown:after {
  border-bottom: 8px solid #fff;
}

.section-dropdown>a {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 10px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
}
.dark-light:checked ~ .sec-center .section-dropdown a {
  color: #102770;
}
.section-dropdown>a:hover {
  color: #102770;
  background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center .section-dropdown a:hover {
  color: #ffeba7;
  background-color: #102770;
}
.section-dropdown>a .uil {
  font-size: 22px;
}


/* for excel */


.sec-center-excel {
  position: relative;
  max-width: 100%;
  text-align: center;
  z-index: 200;
}
.sec-center-excel >[type="checkbox"]:checked,
.sec-center-excel >[type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.dropdown-excel:checked + label,
.dropdown-excel:not(:checked) + label{
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 2;
  height: 40px;
  transition: all 200ms linear;
  border-radius: 7px;
  width: 190px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  gap: 15px;
  border: none;
  background-color: #39532f;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 12px 35px 0 rgba(255,235,167,.15);
}
.dark-light:checked ~ .sec-center .for-dropdown-excel{
  background-color: #fff;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16,39,112,.25);
}
.dropdown-excel:checked + label:before,
.dropdown-excel:not(:checked) + label:before{
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}
.dropdown-excel:checked + label:before{
  pointer-events: auto;
}
.dropdown-excel:not(:checked) + label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.dropdown-excel:checked + label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.section-dropdown-excel {
  position: absolute;
  padding: 5px;
  background-color: #fff;
  top: 52px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center-excel .section-dropdown-excel {
  background-color: #fff;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.15);
}
.dropdown-excel:checked ~ .section-dropdown-excel{
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.section-dropdown-excel:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}
.section-dropdown-excel:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid #fff;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center-excel .section-dropdown-excel:after {
  border-bottom: 8px solid #fff;
}

.section-dropdown-excel>p {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
padding-left: 15px;
  padding-right: 15px;
  margin: 10px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
}
.dark-light:checked ~ .sec-center-excel .section-dropdown-excel p {
  color: #102770;
}
.section-dropdown-excel>p:hover {
  color: #102770;
  background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center-excel .section-dropdown-excel p:hover {
  color: #ffeba7;
  background-color: #102770;
}
.section-dropdown-excel>p .uil {
  font-size: 22px;
}
