.apply-button{
    background-color: rgba(4, 93, 103, 0.833);
    color: rgb(0,0,0,0.9);
    border: none;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 18px;
    font-size: 0.9vw;
    cursor: pointer;
}

/* .assignment-excel .apply-button:hover{
    background-color: rgb(0,0,0,0.8) !important;
    color: white !important;
    border: 0.6px solid rgb(0,0,0,0.4);
} */