.all-leave {
    padding: 10px;
}


.update-leave-request-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px;

}

.update-leave-request-modal .leave-request-modal {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}



.update-leave-request-modal .update-leave-status-btn {
    padding: 0.5rem 1.5rem !important;
    border: none !important;
    color: #ffffff !important;
    background-color: #000000 !important;
    border-radius: 20px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    font-size: 0.9vw !important;
    margin: 20px auto 0px auto !important;
    width: 180px;
}

.update-button-leave {
    align-items: center;
    background-color: #EA4C89;
    border-radius: 80px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    height: 25px;
    line-height: 15px;
    margin: 0;
    padding: 4px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    z-index: 0;
    transition: all ease-in-out 150ms;
}

.update-button-leave:hover,
.update-button-leave:focus {
    background-color: #F082AC;
}

.leave-status-table-container table th {
    text-align: center;
}

.leave-status-table-container table td {
    text-align: center;
}

.rejected-status {
    align-items: center;
    color: #ff6666;
    font-weight: 600;
    z-index: 0;
    text-transform: capitalize;
    cursor: text;
}

.approved-status {
    text-transform: capitalize;
    align-items: center;
    color: #93C572;
    font-weight: 600;
    z-index: 0;
    cursor: text;
}

.leave-status-table-container .employee-select {
    margin-bottom: 12px;
    width: 100%;
}

.leave-status-table-container .employee-select .fields {
    width: 250px;
}