.tyre-claims-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.claim-submit-button{
    padding: 0.4rem 1rem;
    height: 2rem;
    border: none;
    color: #ffffff;
    background-color: #000000;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9vw;
    margin-left: auto;
    margin-right: auto;
}


.button-request-approved{
    border: 1px solid green;
    color: green;
    background-color: #fff;
    padding: 5px;
    outline: none;
    width: 90px;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    
}

.button-request-rejected{
    border: 1px solid red;
    color: red;
    background-color: #fff;
    padding: 5px;
    outline: none;
    width: 90px;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;

}


.approved-bg{
    border: 1px solid green;
    background-color: green;
    color: #fff;
    padding: 5px;
    outline: none;
    width: 95px;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;

}
.rejected-bg{
    border: 1px solid red;
    color: #fff;
    background-color: red;
    padding: 5px;
    outline: none;
    width: 95px;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;

}

.upload-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.file-upload-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.file-upload-div .credit-note{
 padding: 0px 20px;

}
.file-upload-div .credit-note .file-upload{
    width: 230px;
    margin-left: auto;
    margin-right: auto;


}
.credit-note h5{
    font-weight: 600;
    margin-bottom: 8px;
}
.acceptClicked {
    background-color: green;
}

.rejectClicked {
    background-color: red; 
}

.increased-font-size .MuiTooltip-tooltip {
    font-size: 200px; /* Adjust the font size as needed */
}




/* serial number modal css - start */
.serial-eye-modal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;    
}
/* serial number modal css - end*/



