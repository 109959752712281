.img-main-div {
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(1px);
}

.update-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #F1F1F1;
    width: 80%;
    max-width: 59.5rem;
    min-height: 77%;
    border-radius: 0.8rem;
}

.update-modal-body {
    overflow-y: auto;
}

.img-modal-header {
    display: flex;
    justify-content: space-between;
    background-color: #39532F;
    padding: 0.7rem 0.7rem;
    align-items: center;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    margin-bottom: 1.5rem;
}

.img-upload {
    height: 32rem;
    width: 100%;
    overflow-y: auto;

}

.img-upload {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.image-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 5px 3px 5px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-top: 10px;
}

.image-div .imgs img {
    /* border: 1px solid #6f6f6faa; */
    margin: 10px;
    border-radius: 7px;
    padding: 2px;
    cursor: pointer;
}

.def-types {
    display: flex;
    width: 24rem;
    margin-top: 10px;
}

.def-types h1 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.update-img-edit-icon {
    position: absolute;
    background-color: #FB6535;
    padding: 8px;
    top: 12.1rem;
    left: 20.3rem;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.defect-names {
    margin-top: 5px;
}

.defects {
    font-weight: 450;
}

.defect-selection-field {
    width: 384px;
    padding: 10px;
    margin-top: 7px;
    border-radius: 5px;
}

.description-div {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
}

.description-div h2 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.description-div textarea {
    display: flex;
    padding: 10px;
    align-items: center;
    width: 44rem;
    height: 8rem;
    border-radius: 10px;
}

.modal-footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}



.modal-footer button {
    padding: 0.7rem 1.4rem;
    border: none;
    background-color: #65A143;
    color: #F3F2AD;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    margin-bottom: 1rem;
}


.img-upload::-webkit-scrollbar {
    width: 19px;
    background-color: #eae9e9;
    height: 1rem;
    border-bottom-right-radius: 2rem;
}

.img-upload::-webkit-scrollbar-track {
    background-color: #eae9e9;
    border-bottom-right-radius: 0.6rem;
}

.img-upload::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
    background-color: #eae9e9;
}

.img-upload::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
    background-color: #eae9e9;
}

.img-upload::-webkit-scrollbar-thumb {
    background-color: #588048;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
}


@media screen and (max-width:1100px) {
    .description-div textarea {
        width: 30rem;
    }

}

@media screen and (max-width:800px) {
    .description-div textarea {
        width: 25rem;
    }

}

@media screen and (max-width:730px) {
    .description-div textarea {
        width: 20rem;
    }

}