.uploading-container{
    height:100%;
    width:100%;
    position: absolute;
    top:50%;
    left:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);
    z-index:40;
    background-color: white;
}