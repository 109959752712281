.sidebar-container {
  position: sticky;
  height: 100vh;
  padding: 0px;
  gap: 20px;
  top: 0;
  min-height: 100vh;
  z-index: 1;
  box-sizing: border-box;
  overflow: auto;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
}

.sidebar-inner-div {
  position: relative;
  height: auto;
  top: 0;
  position: sticky;
  /* margin: 2.5rem 0; */
  padding: 30px 0px 0px 0px;
}

/* .sidebar-container{
  position: sticky;
  position: fixed;
  min-height: 100vh;
  background-color: white;
 
  -webkit-box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.35);s
  padding-top: 15vh;
  z-index: 1;
  overflow: auto;
} */
.sidebar-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sidebar-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #ffffff;
  font-size: 5rem;
  /* margin: 0 0.6rem; */
  cursor: pointer;
}

.profile-icon {
  height: max-content;
  width: max-content;
  border-radius: 50%;
}

.profile-icon img {
  height: 5vw;
  width: 5vw;
  border-radius: 50%;
}

.sidebar-icons {
  font-size: 1rem;
}

.profile-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
}

.profile-info h3 {
  color: #39532f;
  text-align: justify;
  font-weight: 500;
  font-size: 1.1vw;
}

.dashboard-items-container {
  width: 100%;
  flex: 4;
  display: flex;
  justify-content: center;
  /* background-color: bisque; */
}

.dashboard-items {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* justify-content: space-evenly; */
  width: 100%;
  cursor: pointer;
}

.dashboard-item {
  display: flex;
  padding: 0 1.5rem;
  gap: 1.5rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  text-decoration: none;
}

.dashboard-item span {
  font-weight: 600;
  width: 5%;
  color: #5b5b5b !important;
  font-size: 1rem;
}

.dashboard-item p {
  font-size: 0.9vw;
  font-weight: 600;
  color: #000;
}

.active-item,
.sub-main-item {
  position: relative;
  background-color: pink;
  width: 95%;
  border-radius: 0px 20px 20px 0px;
  background: #fff;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.active-item::before,
.sub-main-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 95%;
  width: 3px;
  border-radius: 10px;
  background-color: #65a143;
}

.active-item::before,
.sub-main-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 95%;
  width: 5px;
  border-radius: 10px;
  background-color: #65a143;
}

.side-main-cont {
  width: 15%;
  transition: 0.2s all ease-in;
}

.hide-side-cont {
  width: 6%;
  transition: 0.2s all ease-out;
}

.hide-side-cont .profile-icon img {
  width: 4vw;
  height: 4vw;
}

.hide-side-cont .sidebar-menu {
  position: unset;
  text-align: center;
}
.hide-side-cont .version-container img {
  width: 100%;
}
.hide-side-cont .version-container p {
  font-size: 0.6vw;
}

.logOut-box {
  width: 100%;
}

.logOut-box button {
  cursor: pointer;
  text-align: left;
  background: transparent;
  border: none;
  width: 150px;
  height: 3.4rem;
  padding-left: 28px;
}

.logOut-box button span {
  font-weight: 600;
  font-size: 0.9vw;
  font-family: "Segoe UI";
  padding: 0 0.6rem;
}

.company-logo {
  text-align: center;
  margin-bottom: 5px;
}

.company-logo img {
  width: 50%;
}

.version-container {
  width: 100%;
  position: absolute;
  bottom: 10;
  padding: 0px 15px;
  text-align: center;
}

.version-container img {
  width: 40%;
}

.version-container .text {
  display: flex;
  justify-content: space-between;
}

.version-container .text p {
  font-size: 0.8vw;
  color: var(--Gray, #333);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.version-container p {
  color: var(--Text-color, #666);
  margin-top: 5px;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1280px) {
  .dashboard-item {
    height: 2.5rem;
  }
  /* .sidebar-container{
    height: calc(100vh + 15rem);
    overflow: visible;
    z-index: 1;
    width: 40vw;
    position: sticky;
  } */
  /* .hide-side-cont {
    width: auto
  }

  .side-main-cont {
    width: 20%;
  } */
}

@media only screen and (max-width: 1045px) {
  .sidebar-container {
    overflow: visible;
    z-index: 1;
    width: 40vw;
    position: sticky;
  }

  .hide-side-cont {
    display: none;
  }

  .side-main-cont {
    width: 20%;
  }
}

@media only screen and (max-width: 850px) {
  .sidebar-container {
    overflow: visible;
    z-index: 1;
    width: 40vw;
    position: fixed;
  }

  .hide-side-cont {
    display: none;
  }

  .side-main-cont {
    width: 27%;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar-container {
    overflow: visible;
    z-index: 1;
    width: 40vw;
    position: fixed;
  }

  .hide-side-cont {
    display: none;
  }

  .side-main-cont {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar-container {
    overflow: visible;
    z-index: 1;
    width: 40vw;
    position: fixed;
  }

  .hide-side-cont {
    display: none;
  }

  .side-main-cont {
    width: 40%;
  }
}
