
.addFleet-form-assign{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.addFleet-form-input-assign{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.addFleet-form-assign .autocomlpete{
    width: 100%;
padding: 0 10px !important;
    /* border: none !important; */
    font-size: 1vw;
}
